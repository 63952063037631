import React from "react";
import { ButtonPrimary } from "@xolvio/xolvio-ui";
import {useContactForm} from './ContactFormContext'

export const JoinTheBetaButton = ({ style = {} }) => {
  const contactForm = useContactForm()

  return (
    <ButtonPrimary
      onClick={contactForm.openContactForm}
      style={{
        padding: "8px 15px",
        margin: 0,
        fontSize: 16,
        color: "white",
        fontWeight: "normal",
        ...style
      }}
    >
      Sign up
    </ButtonPrimary>
  );
};
