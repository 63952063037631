import styled from 'styled-components';
import { b as AcceptanceGreenUltraLight } from '../../../colors-c52fac99.js';

const Emphasis = styled.span `
  padding: 0 2px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 45%,
    ${AcceptanceGreenUltraLight} 45%,
    ${AcceptanceGreenUltraLight} 80%,
    rgba(255, 255, 255, 0) 80%
  );
  font-family: ${(props) => props.font || "inherit"};
  font-weight: ${(props) => (props.bold ? "bold" : "inherit")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "inherit")};
`;

export { Emphasis };
