import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device, b as breakpoints } from '../../../device-db5dbdea.js';

const TwoColumnsWithImage = (props) => (React__default.createElement(Wrapper, { margin: props.marginBetween, imagePosition: props.imagePosition, height: props.height || "700px", baseBreakpoint: props.baseBreakpoint },
    React__default.createElement(ImageWrapper, { height: props.imageHeight, imagePosition: props.imagePosition, marginBetween: props.marginBetween, baseBreakpoint: props.baseBreakpoint }, props.imageNode),
    React__default.createElement(ContentWrapper, { imagePosition: props.imagePosition, marginBetween: props.marginBetween }, props.contentNode)));
TwoColumnsWithImage.defaultProps = {
    marginBetween: "20px",
    imagePosition: "left",
};
const ContentWrapper = styled.div `
  max-width: calc(${spacing.desktop.maxContentWidth}px / 2);
  width: 100%;

  justify-content: center;
  ${(props) => props.imagePosition === "right"
    ? `
        justify-content: flex-end;
    `
    : ""}
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column-reverse;

  > * {
    width: 100%;
  }

  @media ${device.mobile} {
    > * {
      text-align: center;
      width: 100%;
    }
    padding: 0 ${spacing.mobile.padding.default}px;
  }

  @media ${(props) => device[props.baseBreakpoint || "tabletVertical"]} {
    flex-direction: ${(props) => props.imagePosition === "right" ? "row-reverse" : "row"};
    ${(props) => props.imagePosition === "right"
    ? `padding-right: 0px;`
    : "padding-left: 0px;"};
    
    

    > * {
      width: 50%;
      height: ${(props) => props.height || "500px"};
      display: flex;
      align-items: center;
      justify-content: ${(props) => props.imagePosition === "right" ? "flex-start" : "flex-end"};
      
      ${(props) => props.imagePosition === "right"
    ? `
                  &:first-child {
                      margin-left: ${props.margin || "20px"};
                    }
                    
                    &:nth-child(2) {
                    @media (min-width: ${breakpoints[props.baseBreakpoint || "tabletVertical"]
        .start}px) and (max-width: ${breakpoints[props.baseBreakpoint || "tabletVertical"].end}px) {
                        padding-left: 24px !important;
                      }
                    }
              `
    : `
                    &:nth-child(2) {
                      margin-left: ${props.margin || "20px"};
                    }
            `}
    }
  }
  }
`;
const ImageWrapper = styled.div `
  @media ${(props) => device[props.baseBreakpoint || "tabletVertical"]} {
    width: calc(50% + 48px);

    ${(props) => props.imagePosition === "right"
    ? `padding-left: ${props.marginBetween}`
    : `padding-right: ${props.marginBetween}`};

    height: ${(props) => props.height || "700px"};
  }

  @media ${(props) => device[props.baseBreakpoint || "tabletVertical"]} {
    width: calc(50% - 40px);
  }
`;

export { TwoColumnsWithImage };
