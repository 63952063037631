import React__default, { useEffect, createRef, useState } from 'react';
import styled from 'styled-components';
import '../../../../spacing-ad86e0c5.js';
import { d as device } from '../../../../device-db5dbdea.js';
import { d as AlertRed } from '../../../../colors-c52fac99.js';
import { TextBody, SectionHeaderSmall } from '../../typography.js';
import '../../layout/CenteredContentWrapper.js';
import { ButtonPrimary } from '../../ButtonPrimary/ButtonPrimary.js';
import { EnvelopeIcon } from '../../Icons/EnvelopeIcon.js';
import { UserIcon } from '../../Icons/UserIcon.js';
import { InputWithIconWrapper, Textarea } from '../ContactUs/ContactUs.js';
import { Loader } from '../../Loader/Loader.js';
import '../../layout/Spacing.js';
import 'axios';
import { u as useContactFormLogic, F as FormStatus } from '../../../../useContactFormLogic-be2eca8a.js';
import '../../../../_commonjsHelpers-97e6d7b1.js';
import '../../../../_react_commonjs-external-04d0b979.js';
import { CalendlyScheduleButton } from '../CalendlyScheduleButton.js';

const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        // Bind the event listener
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

const ContactUsSlim = ({ sourceUrl = "", }) => {
    const formRef = createRef();
    const logic = useContactFormLogic("contact-us-submit", "Xolv.io - contact form", { sourceUrl });
    const [showFullForm, setShowFullForm] = useState(false);
    useOutsideClick(formRef, () => setShowFullForm(false));
    switch (logic.status) {
        case FormStatus.ERROR:
            return (React__default.createElement(SectionHeaderSmall, { id: "contact-form-submitted", style: {
                    maxWidth: "100%",
                    textAlign: "center",
                    fontWeight: 100,
                    color: AlertRed,
                } }, "Oops, something went wrong. Please try again."));
        case FormStatus.LOADING:
            return (React__default.createElement(SectionHeaderSmall, { style: {
                    maxWidth: "100%",
                    textAlign: "center",
                    fontWeight: 100,
                    fontSize: "22px",
                } },
                React__default.createElement(Loader, null)));
        case FormStatus.SUCCESS:
            return (React__default.createElement(SectionHeaderSmall, { id: "contact-form-submitted", style: {
                    maxWidth: "100%",
                    textAlign: "center",
                    fontWeight: 100,
                    fontSize: "22px",
                } }, "Thank you for your message. We\u2019ll be in touch shortly."));
        default:
            return (React__default.createElement(Wrapper, { showFullForm: showFullForm },
                React__default.createElement("form", { ref: formRef, onSubmit: logic.onSubmit },
                    React__default.createElement(TextareaStyled, { name: "message", onChange: logic.onChange, "data-testid": "message", placeholder: "Send us a message", onFocus: () => setShowFullForm(true) }),
                    React__default.createElement(Animator, { show: showFullForm },
                        React__default.createElement(InputsWrapper, null,
                            React__default.createElement(InputWithIconWrapper, null,
                                React__default.createElement(UserIcon, null),
                                React__default.createElement("input", { name: "name", onChange: logic.onChange, placeholder: "Your name", type: "text", "data-testid": "name-input", required: true })),
                            React__default.createElement(InputWithIconWrapper, null,
                                React__default.createElement(EnvelopeIcon, null),
                                React__default.createElement("input", { name: "email", onChange: logic.onChange, placeholder: "Your email", type: "email", "data-testid": "email-input", required: true }))),
                        React__default.createElement(ButtonPrimary, { type: "submit", style: { width: "100%", color: "white !important" } }, "Send message"))),
                React__default.createElement(TextBody, { style: { marginTop: 12 } }, "or"),
                React__default.createElement(CalendlyScheduleButton, null)));
    }
};
const InputsWrapper = styled.div `
  margin-top: 10px;
  margin-bottom: 6px;
  @media ${device.tablet} {
    display: flex;
    > * {
      flex-grow: 1;
    }
    > *:last-child {
      margin-left: 24px;
    }
  }
`;
const Wrapper = styled.div `
  display: flex !important;
  flex-direction: column;
  margin-bottom: 24px;
  > * {
    max-width: 100%;
    text-align: center;
  }

  @media ${device.tablet} {
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;

    > *:first-child,
    > *:last-child {
      width: 45%;
    }

    > :nth-child(2) {
      width: 10%;
    }

    > * {
      box-sizing: border-box;
    }
  }

  * {
    transition: width 0.5s, height 0.5s, transform 0.5s, opacity 0.5s;
  }

  ${({ showFullForm }) => showFullForm
    ? `
        textarea {
          height: 150px !important;
          transition-delay: 0.2s
          overflow: auto;
        }
        > *:first-child {
          width: 100%;
        }
        > *:not(:first-child) {
          width: 0%;
          opacity: 0;
          padding-left: 0;
          padding-right: 0;
          transform: scaleX(0);
          max-height: 50px;

        }
    `
    : ""}
`;
const TextareaStyled = styled(Textarea) `
  height: 50px;
  min-height: 50px;
  padding: 14px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  font-size: 18px;
`;
const Animator = styled.div `
  transform: scaleY(0);
  max-height: 0px;
  opacity: 0;

  transition: scale 0.5s, max-height 0.5s, opacity 0.7s;
  ${({ show }) => show
    ? `
        opacity: 1;
        transform: scaleY(1);
        height: 100%;
        max-height: 1000px`
    : ``}
`;

export { ContactUsSlim };
