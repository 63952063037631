import styled from 'styled-components';

const Button = styled.button `
  background: rgb(89, 190, 152);
  border: none;
  outline: none;
  box-sizing: border-box;
  height: 2.5rem;
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  padding: 0 2rem;

  margin-left: 15px;
  font-family: HelveticaNeue;
  font-weight: bold;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background: #bceed9;
  }

  &:disabled {
    color: #d5d9d8;
    background: #b0b6b8;
  }
`;
const ButtonSecondary = styled(Button) `
  margin-left: 15px;
  background: #bceed9;
  color: #1b9668;
  height: 2rem;
  font-size: 0.8rem;
`;

export default Button;
export { Button, ButtonSecondary };
