import styled from "styled-components";
import {
  TwoColumnsWrapper,
  spacing,
  SectionHeader,
  device,
  TextBody,
  Header,
  colors
} from "@xolvio/xolvio-ui";
import BackgroundImage from "gatsby-background-image";

export const SectionWrapper = styled(TwoColumnsWrapper)`
  flex-direction: column;
  padding: 0 !important;
`;

export const ImageWrapper = styled.div`
  display: block;
  max-width: 100%;
`;

export const Spacing = styled.div`
  height: ${props => props.mobile || spacing.mobile.betweenSections}px;

  @media ${device.tablet} {
    height: ${props => props.tablet || spacing.mobile.betweenSections}px;
  }

  ${props =>
    props.hideOnMobile
      ? `@media ${device.mobile} {
    display: none;
  `
      : ""};
`;

export const SectionHeaderStyled = styled(SectionHeader)`
  margin-top: 16px;
  margin-bottom: 24px;

  @media ${device.mobile} {
    font-size: 30px;
  }
`;

export const TextBodyStyled = styled(TextBody)`
  margin: 24px 0;
  max-width: 430px;
  font-family: HK Grotesk;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

export const TextBodySmall = styled(TextBodyStyled)`
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const HeroBackgroundImage = styled(BackgroundImage)`
  height: 710px;

  z-index: -1;
  background-size: contain;
  background-position: right;
  max-height: 80vw;
  position: absolute;
  right: 0;

  @media ${device.mobile} {
    height: 310px;

    background-position: top;
  }
`;

export const HeaderStyled = styled(Header)`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const Separator = styled.div`
  margin: 0 auto;
  width: 64px;
  height: 4px;
  background-color: ${colors.AcceptanceGreen};
  margin-top: 40px;

  @media ${device.tabletVertical} {
    margin-top: 0;
    margin-left: 0;
  }
`;

export const SectionHeaderWrapper = styled.div`
  text-align: center;
  max-width: 100%;
  width: 100%;
  margin: auto;

  > * {
    margin-right: auto;
    text-align: center;
    max-width: 100%;
    width: 100%;
  }
`;
