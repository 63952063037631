import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { e as AcceptanceGreenUltimatelyLight } from '../../../colors-c52fac99.js';
import { Confetti3Rows } from '../Icons/Confetti3Rows.js';

const ConfettiHR = (props) => (React__default.createElement(Wrapper, { style: props.style },
    React__default.createElement(Confetti3Rows, null)));
const Wrapper = styled.div `
  position: relative;
  padding: 10px;
  background-color: ${AcceptanceGreenUltimatelyLight};
  display: flex;
  margin-bottom: 130px;
  margin-left: 30px;

  @media ${device.desktop} {
    margin-left: 60px;
  }
`;

export { ConfettiHR };
