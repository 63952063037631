import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const CenteredContentWrapper = styled.div `
  margin: 0 ${spacing.mobile.padding.default}px;
  max-width: 100%;

  @media ${device.desktop} {
    max-width: ${spacing.desktop.maxContentWidth}px;
    margin-left: ${spacing.mobile.padding.default}px;
    margin-right: ${spacing.mobile.padding.default}px;
  }

  @media ${device.wide} {
    max-width: ${spacing.desktop.maxContentWidth}px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export { CenteredContentWrapper };
