import React, { createRef, useState } from "react";
import styled from "styled-components";
import {
  EnvelopeIcon,
  spacing,
  ButtonPrimary,
  CenteredContentWrapper,
  colors,
  BuildingsIcon,
  UserIcon,
  TextBody,
  Loader
} from "@xolvio/xolvio-ui";
import HKGrotesk from "../assets/fonts/HKGrotesk-Light.otf";
import HKGroteskBold from "../assets/fonts/HKGrotesk-Bold.otf";

const config = {
  formUrl: "https://uq0po2vi71.execute-api.us-east-1.amazonaws.com/prod/"
};

export const JoinTheBetaForm = ({ method, contactActionUrl }) => {
  const [submitResult, setSubmitResult] = useState();

  const formRef = createRef();

  const doSubmit = async function(e) {
    e.preventDefault();
    setSubmitResult("LOADING");

    if (formRef.current) {
      try {
        const formData = new FormData(formRef.current);
        const formDataEntries = {};

        formData.forEach((value, key) => {
          formDataEntries[key] = value;
        });
        //required for universal form API gateway
        formDataEntries.sender = "XSpecs - contact form";
        const response = await fetch(config.formUrl, {
          method: method,
          body: JSON.stringify(formDataEntries)
        });

        if (response && response.status === 200) {
          setSubmitResult("SUCCESS");
          try {
            if (typeof window !== 'undefined') {
              window.gtag('event', 'generate_lead', {
                mail: formDataEntries.email || '',
              })
            }
          } catch (e) {
            console.error('Form event error', e);
          }
        } else {
          setSubmitResult("ERROR");
        }
      } catch (error) {
        setSubmitResult("ERROR");
      }
    }
  };

  return (
    <Wrapper data-testid={"enterprise-pricing-form"}>
      {submitResult === "ERROR" && (
        <TextBody style={{ color: colors.AlertRed, maxWidth: "100%" }}>
          Oops, something went wrong. Please try again.
        </TextBody>
      )}
      {submitResult === "SUCCESS" ? (
        <TextBody style={{ maxWidth: "100%" }}>
          You’re signed up for the beta waitlist! We’ll send you an email as soon as XSpecs goes live.
        </TextBody>
      ) : (
        <FormWrapper>
          <FormTitle>
            Sign up for the beta waitlist to get notified when we go live in the coming weeks.
          </FormTitle>
          <form ref={formRef} onSubmit={doSubmit}>
            <InputWithIconWrapper>
              <UserIcon />
              <input name="name" placeholder={"Name"} type="text" required />
            </InputWithIconWrapper>

            <InputWithIconWrapper>
              <BuildingsIcon />
              <input name="company" placeholder={"Company (optional)"} type="text" />
            </InputWithIconWrapper>

            <InputWithIconWrapper>
              <EnvelopeIcon />
              <input
                placeholder={"Your email"}
                name="email"
                type="email"
                required
              />
            </InputWithIconWrapper>

            {/* Info: Jira question disabled */}
            {/*<JiraQuestion>*/}
            {/*  Do you use Jira?*/}
            {/*  <label><input type="radio" name="jira" value="yes" required />Yes</label>*/}
            {/*  <label><input type="radio" name="jira" value="no" />No</label>*/}
            {/*</JiraQuestion>*/}

            {submitResult === "LOADING" ? (
              <Loader />
            ) : (
              <ButtonPrimary type="submit" style={{ width: "100%", margin: 0 }}>
                Sign up
              </ButtonPrimary>
            )}
          </form>
        </FormWrapper>
      )}
    </Wrapper>
  );
};

JoinTheBetaForm.defaultProps = {
  method: "POST"
};

const Wrapper = styled(CenteredContentWrapper)`
  padding: ${spacing.mobile.padding.default}px;
  margin: 0 !important;
  border: 1px solid ${colors.AcceptanceGreen};

  @font-face {
    font-family: "HK Grotesk";
    src: url(${HKGrotesk});
    src: url(${HKGrotesk}) format("opentype");
    font-display: block;
  }
  @font-face {
    font-family: "HK Grotesk";
    font-weight: bold;
    src: url(${HKGroteskBold});
    src: url(${HKGroteskBold}) format("opentype");
    font-display: block;
  }
  
  font-family: "HK Grotesk", sans-serif;
`;

const FormTitle = styled.p`
  text-align: left;
`

const FormWrapper = styled.div``;

const InputWithIconWrapper = styled.div`
  position: relative;

  input,
  textarea {
    padding: 0 0 0 60px;
    width: 400px;
    max-width: calc(100% - 62px);
    font-size: 14px;
    height: 46px;
    border-radius: 2px;
    border: solid 1px #d8dcdb;
    color: ${colors.TextMedium};
    margin-top: 10px;
    margin-bottom: 16px;

    &::placeholder {
      color: #6b726f;
    }
  }

  textarea {
    height: 30px;
    min-height: 70px;
    padding-top: 16px;
    padding-left: 10px;
    width: 100%;
    max-width: calc(100% - 12px);
    resize: vertical;
  }

  svg {
    margin-top: 22px;
    margin-left: 12px;
    position: absolute;
    padding-right: 12px;
    border-right: 1px solid #d8dcdb;
  }
`;

const JiraQuestion = styled.div`
  margin-bottom: 20px;
  
  label {
    margin-left: 5px;
  }
`
