import React__default from 'react';

const CircleCILogo = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 256 259", preserveAspectRatio: "xMinYMin meet", height: props.size || "80" }, props),
    React__default.createElement("g", { fill: "#343434" },
        React__default.createElement("circle", { cx: "126.157", cy: "129.008", r: "30.593" }),
        React__default.createElement("path", { d: "M1.204 96.572c0 .368-.369 1.105-.369 1.474 0 3.317 2.58 6.266 6.266 6.266h51.972c2.58 0 4.423-1.474 5.529-3.686 10.69-23.59 34.28-39.44 61.186-39.44 37.228 0 67.453 30.225 67.453 67.453s-30.225 67.453-67.453 67.453c-27.276 0-50.497-16.218-61.186-39.071-1.106-2.58-2.949-4.055-5.53-4.055H7.103c-3.318 0-6.267 2.58-6.267 6.267 0 .368 0 1.105.369 1.474 14.375 56.026 64.872 97.309 124.953 97.309 71.139 0 129.008-57.87 129.008-129.008C255.165 57.869 197.295 0 126.157 0 66.077 0 15.579 41.283 1.204 96.572z" }))));
CircleCILogo.defaultProps = {
    "data-testid": "circleci-logo",
};

export { CircleCILogo };
