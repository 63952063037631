import React from "react";
import styled from "styled-components";
import { spacing } from "@xolvio/xolvio-ui";
import { graphql, StaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

export const TileContentScreenshotTemplate = ({ num }) => (
  <Wrapper>
    <StaticQuery
      query={graphql`
      query {
        step1: file(relativePath: { eq: "screenshot-step1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        step2: file(relativePath: { eq: "screenshot-step2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        step3: file(relativePath: { eq: "screenshot-step3.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        step4: file(relativePath: { eq: "screenshot-step4.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        step4part2: file(relativePath: { eq: "screenshot-step4part2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
      render={(data) => {
        return (
          <>
            <Image
              fluid={data[`step${num}`].childImageSharp.fluid}
              durationFadeIn={200}
            />
            {
              num === 4 && (
                <Image
                  fluid={data['step4part2'].childImageSharp.fluid}
                  durationFadeIn={200}
                />
              )
            }
          </>
        );
      }}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: ${spacing.mobile.padding.default}px;
  width: 100%;
`;

const Image = styled(GatsbyImage)`
  margin-right: 10px;
  margin-bottom: 40px;
  box-shadow: 0 16px 40px -4px rgba(86,191,152,0.15);
`
