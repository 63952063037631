import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { T as TextBlack } from '../../../colors-c52fac99.js';
import { Subheading } from '../typography.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import '../ButtonPrimary/ButtonPrimary.js';
import { XolvioLogoVertical } from '../Icons/XolvioLogoVertical.js';
import '../Icons/TwitterIcon.js';
import '../Icons/LinkedInIcon.js';
import '../Icons/YoutubeIcon.js';
import { SocialIcons } from '../SocialIcons/SocialIcons.js';
import '../Icons/EnvelopeIcon.js';
import NewsletterForm from './NewsletterForm.js';

const Footer = ({ linkGroups, legaleseLinks, socialLinks, contactActionUrl, hideFooterLinks, customRightsMessage, }) => (React__default.createElement(CenteredContentWrapper, null,
    React__default.createElement(FooterWrapper, null,
        !hideFooterLinks && (React__default.createElement(React__default.Fragment, null,
            React__default.createElement(LinksWrapper, null,
                React__default.createElement(XolvioLogoVertical, { onClick: () => (window.location.href = "/"), style: { cursor: "pointer" } }),
                linkGroups.map((linksGroup) => (React__default.createElement(LinksGroup, Object.assign({}, linksGroup, { key: linksGroup.name }))))),
            React__default.createElement(SocialWrapper, null,
                React__default.createElement(NewsletterForm, { contactActionUrl: contactActionUrl }),
                React__default.createElement(SocialLinks, null,
                    React__default.createElement(Subheading, null, "OR FOLLOW US:"),
                    React__default.createElement(SocialIcons, Object.assign({}, socialLinks)))))),
        React__default.createElement(LegaleseWrapper, null,
            legaleseLinks.map((link) => (React__default.createElement(LegalLink, { onClick: link.onClick, href: link.onClick ? undefined : link.url, key: link.url, style: {
                    letterSpacing: "-0.1px",
                    color: "#d8dcdb",
                } }, link.name))),
            React__default.createElement(CopyrightNotice, null, customRightsMessage || `${new Date().getFullYear()} Xolvio, Inc., All Rights Reserved. Xolvio, XSpecs and Quality Faster are registered trademarks of Xolvio, Inc.`)))));
Footer.defaultProps = {
    linkGroups: [],
};
const LinksGroup = (props) => (React__default.createElement(LinksGroupWrapper, null,
    React__default.createElement(Subheading, null, props.name),
    props.links &&
        props.links.map((link) => (React__default.createElement(FooterLink, { onClick: link.onClick, href: link.onClick ? undefined : link.url, key: link.url, target: link.target, rel: "noopener" }, link.name)))));
const FooterWrapper = styled.div `
  margin-top: ${spacing.mobile.padding.default}px;
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 62% 1fr;
  }
`;
const FooterLink = styled.a `
  cursor: pointer;
  display: block;
  padding: 6px 0;
  text-decoration: none;
  color: ${TextBlack};
`;
const LinksWrapper = styled.div `
  h1 {
    margin-bottom: 8px;
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${2 * spacing.mobile.padding.default}px;
  margin-bottom: ${2 * spacing.mobile.padding.default}px;

  * {
    text-align: left !important;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const LinksGroupWrapper = styled.div `
  :nth-child(2n) {
    margin-left: 40px;
  }

  @media ${device.desktop} {
    :nth-child(2n) {
      margin-left: 0;
    }
  }
`;
const SocialWrapper = styled.div `
  h1 {
    width: 100%;
    text-align: left;
  }

  @media ${device.tabletVertical} {
    h1 {
      width: auto;
      margin-right: 20px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.desktop} {
    display: block;
    margin-left: 32px;
    padding-left: 32px;
    border-left: 1px solid #d8dcdb;
  }
  margin-bottom: ${spacing.mobile.padding.default}px;
`;
const SocialLinks = styled.div `
  display: flex;
  padding: 50px 0 20px;

  @media ${device.tabletVertical} {
    flex-direction: column;
  }

  @media ${device.tabletVertical} {
    padding: 0 0 0 40px;
    h1 {
      margin-bottom: 24px;
    }
  }

  @media ${device.desktop} {
    flex-direction: row;
    padding: 20px 0 0 0;
    h1 {
      margin-bottom: 0px;
    }
  }
`;
const LegaleseWrapper = styled.div `
  position: relative;
  grid-column: span 2;
  border-top: 1px solid #d8dcdb;
  display: inline-block;

  > a {
    display: inline-block;
    margin: 30px 30px 30px 0;
  }

  @media ${device.tabletVertical} {
    width: 100%;
  }
`;
const LegalLink = styled.a `
  text-decoration: none;
  font-size: 12px;
  letter-spacing: -0.1px;
  color: #d8dcdb;
  @media ${device.desktop} {
    padding-top: 6px;
    font-size: 14px;
  }
`;
const CopyrightNotice = styled.div `
  font-size: 12px;
  letter-spacing: -0.1px;
  color: #d8dcdb;
  @media ${device.desktop} {
    position: absolute;
    right: 0;
    top: 36px;
    font-size: 14px;
  }
`;

export { CopyrightNotice, Footer, FooterWrapper, LegalLink, LegaleseWrapper, LinksGroup, LinksGroupWrapper, LinksWrapper, SocialLinks, SocialWrapper };
