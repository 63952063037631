import React__default, { Component } from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';
import { MenuItem } from './MenuItem.js';
import { MenuButtons } from './MenuButtons.js';
import { MenuItemsWrapper } from './MenuItemsWrapper.js';

const elementDelay = 0.1;
class HeaderDesktop extends Component {
    render() {
        const { items, animate, logo, primaryButtonUrl, primaryButtonText, primaryButtonElement, } = this.props;
        const desktopItems = items.filter((item) => item.hideOnDesktop !== true);
        return (React__default.createElement(Wrapper, null,
            React__default.createElement(MenuButtons, null,
                logo,
                React__default.createElement(MenuItemsWrapper, { open: true, simpleMenu: this.props.simpleMenu },
                    desktopItems.map((item, index) => (React__default.createElement(React__default.Fragment, null,
                        React__default.createElement(MenuItem, Object.assign({ desktop: true, key: item.name, style: {
                                animationDelay: animate ? `${index * elementDelay}s` : "0s",
                            }, animate: animate }, item))))),
                    primaryButtonText && (React__default.createElement(ButtonPrimaryLink, { as: "a", href: primaryButtonUrl, style: { padding: "8px 15px", fontSize: 16 } }, primaryButtonText)),
                    !primaryButtonText && primaryButtonElement))));
    }
}
const Wrapper = styled.div `
  width: 100%;
  position: absolute;
  @media ${device.mobile} {
    display: none;
  }
`;

export { HeaderDesktop };
