import styled from 'styled-components';
import Button from './Button.js';

const InlineButton = styled(Button) `
  font-style: italic;
  font-weight: normal;
  text-decoration: underline;
  background: none;
  padding: 0;
  height: auto;
  font-size: 0.9rem;

  &:hover {
    background: none;
  }
`;

export { InlineButton };
