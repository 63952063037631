import styled from 'styled-components';
import { f as GREY02, g as GREY04 } from '../../../colors-c52fac99.js';

const Input = styled.input `
  box-sizing: border-box;
  height: 2.5rem;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  border: 1px solid #59be98;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;

  &&& {
    margin: auto 0 auto 5px;

    &[disabled] {
      color: ${GREY02};
      background-color: ${GREY04};
      border: 1px solid ${GREY04};
    }
  }
`;

export default Input;
