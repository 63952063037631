import {JoinTheBetaButton} from '../components/JoinTheBetaButton'
import React from 'react'
import { benefits } from "./benefits";

export const pageData = {
  hero: {
    // following header is being used as title in metadata - see SEO.js component and gatsby-config.js
    header: ["Build more", "with less."],
    highlight: "less",
    body: "Seamlessly go from requirements to code with XSpecs, the AI-assisted Spec-Driven Development platform.",
    button: <JoinTheBetaButton style={{ padding: '0' }} />,
    // buttonOnClick: () => goToHomepageAnchor("about")
    // if buttonUrl is uncommented it takes priority over buttonOnClick
    // buttonUrl: appFormUrl
  },
  manifesto: {
    subHeading: "MANIFESTO",
    header: "The Spec-Driven Revolution",
    highlight: "Spec-Driven",
    body: [
      [
        "We want to live in a world where every software product is built right the first time round.",
        "We envision a future where every single requirement is unambiguous and understood.",
        "We believe everyone should have end-to-end visibility into the entire software development lifecycle.",
      ],
      [
        "No more misunderstandings, no more ephemeral tickets, no tribal knowledge, and no disconnect between team members.",
      ],
    ],
    boldBody: [
      [
        "We’re on a mission to change the software development world and make this vision a reality.",
        "That’s why we're building XSpecs—the Spec-Driven Delivery platform.",
      ],
    ]
  },
  whyXspecs: {
    subHeading: "A NEW WAY TO COLLABORATE",
    header: ["Near real-time development"],
    highlight: "real-time",
    body: "XSpecs lets you deliver a fully functional backend with a GraphQL API within just a 2-hour video call. This is an entirely new way to develop software, where product, tech, and design roles collaborate on specifications—the missing link between requirements and code. The powerful AI features not only help you quickly define the specs, but also automate coding and deployment."
  },
  refactoring: {
    subHeading: "FOR REFACTORING",
    header: ["Legacy system overhaul"],
    highlight: "overhaul",
    body:
      "Connect your codebase to reverse engineer your legacy system into AI-generated specifications. Review and refine the specs, then generate and deploy a refactored, integration-ready backend. XSpecs lets you replace long months of heavy lifting with a streamlined process that makes legacy modernization easier than ever.",
  },
  greenfieldProjects: {
    subHeading: "FOR GREENFIELD PROJECTS",
    header: ["Rapid innovation delivery"],
    highlight: "innovation",
    body: "Model your business processes in the visual Domain Designer. Define the specs for every node. Generate backend code, then review and edit as needed. Finally, deploy to our cloud or your own infrastructure. The AI Assist accelerates every step, bringing your delivery cycle down from weeks to hours."
  },
  benefits
};
