import styled from 'styled-components';
import { G as GREY03 } from '../../../colors-c52fac99.js';

const ActionViewButton = styled.button `
  border: 1px solid ${GREY03};
  background: white;
  border-radius: 3px;
  margin: 5px;
  height: 1.5rem;
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0 10px;
`;

export { ActionViewButton };
