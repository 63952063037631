import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import '../../../device-db5dbdea.js';
import { T as TextBlack } from '../../../colors-c52fac99.js';
import { Subheading, TextBody } from '../typography.js';

const TeamMember = (props) => (React__default.createElement(Wrapper, { "data-testid": "team-member" },
    React__default.createElement(ImageWrapper, null, props.elementWithBackgroundImage),
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(Overlay, null,
            React__default.createElement(Subheading, null, props.position),
            React__default.createElement(TextBody, { bold: true, color: TextBlack, style: { marginTop: 0 } }, props.name)))));
const teamMemberHeight = 320;
const Wrapper = styled.div `
  position: relative;
  width: 232px;
  height: ${teamMemberHeight}px;
`;
const ImageWrapper = styled.div `
  position: relative;

  :first-child {
    background-color: #f4f7f6;
  }

  width: 232px;
  height: 257px;
`;
const Overlay = styled.div `
  padding-left: 30px;
  padding-top: ${spacing.mobile.padding.default}px;
  width: calc(100% - 30px);
  background-color: white;
`;
const ContentWrapper = styled.div `
  height: 500px;
  left: 30px;
  width: calc(100% - 30px);
  white-space: pre-line;
  position: relative;
  top: -65px;
  * {
    text-align: left;
  }
`;

export { TeamMember, teamMemberHeight };
