import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { T as TextBlack } from '../../../colors-c52fac99.js';
import { SectionHeaderSmall, TextBody } from '../typography.js';
import '../ButtonPrimary/ButtonPrimary.js';
import { B as ButtonInlineLink } from '../../../InlineButton-54d8331c.js';

const IconTout = ({ title, subheading, content, icon, url, buttonText, }) => (React__default.createElement(Wrapper, null,
    React__default.createElement(IconWrapper, null, icon),
    React__default.createElement(ContentWrapper, null,
        title && React__default.createElement(SectionHeaderSmall, null, title),
        React__default.createElement(TextBody, { bold: true, color: TextBlack }, subheading),
        React__default.createElement(TextBody, null, content),
        React__default.createElement(ButtonInlineLink, { href: url, as: "a", rel: "noopener" }, buttonText))));
IconTout.defaultProps = {
    buttonText: "Learn more",
};
const IconWrapper = styled.div `
  height: 100%;
  svg {
    width: 50px;
  }
`;
const ContentWrapper = styled.div `
  > * {
    text-align: left;
  }

  margin-left: ${spacing.mobile.padding.default}px;
`;
const Wrapper = styled.div `
  display: flex;

  @media ${device.desktop} {
    padding: 0;
  }
`;

export { IconTout };
