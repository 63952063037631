import React__default from 'react';
import styled from 'styled-components';

const SupergraphIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("g", { id: "Layer_1" },
        React__default.createElement("path", { className: "cls-1", d: "M58.96,71.72c0,7.04,5.71,12.75,12.75,12.75,7.04,0,12.75-5.71,12.75-12.75h-25.51Z" }),
        React__default.createElement("path", { className: "cls-1", d: "M71.72,28.28h12.57c0-6.94-5.63-12.57-12.57-12.57v12.57" }),
        React__default.createElement("path", { className: "cls-2", d: "M78.78,63.3c1.94-4.18,2.93-8.65,2.93-13.3s-.99-9.12-2.93-13.3c2.4-2.02,3.93-5.04,3.93-8.42,0-6.07-4.93-11-11-11-3.38,0-6.4,1.53-8.42,3.93-4.18-1.94-8.65-2.93-13.3-2.93s-9.12,.99-13.3,2.93c-2.02-2.4-5.04-3.93-8.42-3.93-6.07,0-11,4.93-11,11s4.93,11,11,11,11-4.93,11-11c0-1.96-.52-3.8-1.43-5.4,3.82-1.72,7.9-2.6,12.14-2.6s8.32,.88,12.14,2.6c-.9,1.6-1.43,3.44-1.43,5.4,0,2.67,.96,5.13,2.55,7.03l-6.24,6.24c-1.91-1.59-4.36-2.55-7.03-2.55-6.07,0-11,4.93-11,11s4.93,11,11,11,11-4.93,11-11c0-2.67-.96-5.13-2.55-7.03l6.24-6.24c1.91,1.59,4.36,2.55,7.03,2.55,1.96,0,3.8-.52,5.4-1.43,1.72,3.82,2.6,7.9,2.6,12.14s-.88,8.32-2.6,12.14c-1.6-.9-3.44-1.43-5.4-1.43-6.07,0-11,4.93-11,11s4.93,11,11,11,11-4.93,11-11c0-3.38-1.53-6.4-3.93-8.42ZM28.28,37.28c-4.96,0-9-4.04-9-9s4.04-9,9-9,9,4.04,9,9-4.04,9-9,9Zm21.72,21.72c-4.96,0-9-4.04-9-9s4.04-9,9-9,9,4.04,9,9-4.04,9-9,9Zm12.72-30.72c0-4.96,4.04-9,9-9s9,4.04,9,9-4.04,9-9,9-9-4.04-9-9Zm9,52.43c-4.96,0-9-4.04-9-9s4.04-9,9-9,9,4.04,9,9-4.04,9-9,9Z" })),
    React__default.createElement("g", { id: "ludziki" },
        React__default.createElement("path", { className: "cls-1", d: "M35,69s2-3,2-5-2-4-2-4l-4,4v-13h18v18h-14Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M50,50H30v5.83h-.15l-8.45,8.45v16.04h-1.41v5.57h2v-3.57h16.43v3.57h2v-5.57h-3.13l3.25-3.24v-4.43h-2v3.6l-4.07,4.07h-11.07v-15.21l6.6-6.61v5.01l-4.93,4.94,1.41,1.41,8.26-8.26h.01c.69,.7,1.07,1.61,1.07,2.59s-.38,1.9-1.07,2.59l-1.22,1.22-.43,.43,.23,.61s.15,.39,.28,.96c.22,1,.41,2.59-.33,3.73-.59,.91-1.73,1.42-3.39,1.52l.12,2c2.34-.14,4.01-.96,4.96-2.44,.99-1.56,.92-3.46,.68-4.81h14.35v-20Zm-2,18h-11.66c.96-1.04,1.48-2.39,1.48-3.81,0-1.51-.59-2.93-1.66-4l-1.42-1.42-2.74,2.74v-9.51h16v16Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M50,50H30v5.83h-.15l-8.45,8.45v16.04h-1.41v5.57h2v-3.57h16.43v3.57h2v-5.57h-3.13l3.25-3.24v-4.43h-2v3.6l-4.07,4.07h-11.07v-15.21l6.6-6.61v5.01l-4.93,4.94,1.41,1.41,8.26-8.26h.01c.69,.7,1.07,1.61,1.07,2.59s-.38,1.9-1.07,2.59l-1.22,1.22-.43,.43,.23,.61s.15,.39,.28,.96c.22,1,.41,2.59-.33,3.73-.59,.91-1.73,1.42-3.39,1.52l.12,2c2.34-.14,4.01-.96,4.96-2.44,.99-1.56,.92-3.46,.68-4.81h14.35v-20Zm-2,18h-11.66c.96-1.04,1.48-2.39,1.48-3.81,0-1.51-.59-2.93-1.66-4l-1.42-1.42-2.74,2.74v-9.51h16v16Z" }))));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
SupergraphIcon.defaultProps = {
    "data-testid": "icon-supergaph",
};

export { SupergraphIcon };
