import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const MenuButtons = styled.div `
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 6px;
  background-color: white;
  z-index: 10;

  @media ${device.desktopUpToContentWidth} {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  @media ${device.desktop} {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
`;

export { MenuButtons };
