import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const MenuItemsWrapper = styled.div `
  margin-top: 72px;
  position: fixed;
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  opacity: 0.95;
  color: black;
  transition: height 0.3s ease;
  height: ${(props) => props.open ? `calc(100vh - 70px)` : 0};

  > * {
    padding-top: ${2 * spacing.mobile.padding.default}px);
  }
  
  @media ${device.desktop} {
    width: auto;
    margin-top: 0;
    padding: 0;

    position: relative;
    height: 40px;
    margin-top: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    button {
      margin-left: 45px;
    }
  }
  
  ${(props) => props.simpleMenu
    ? `
      align-items: baseline;
      * {font-weight: 500;} 
      `
    : ""};
`;

export { MenuItemsWrapper };
