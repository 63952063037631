import React from "react";
import styled from "styled-components";
import { Footer, device } from "@xolvio/xolvio-ui";
import {
  defaultHeaderProps,
  defaultFooterProps,
} from "../pages-content/common";
import HKGrotesk from "../assets/fonts/HKGrotesk-Light.otf";
import HKGroteskBold from "../assets/fonts/HKGrotesk-Bold.otf";
import "../reset.css";
import { Menu, XSpecsLogoVertical } from "@xolvio/xolvio-ui";
import SEO from "../components/SEO";
import { JoinTheBetaButton } from "../components/JoinTheBetaButton";
import { useHubSpot } from "../useHubSpot";
import { ContactFormContextProvider } from "../components/ContactFormContext";

export const LayoutDefault = (props) => {
  useHubSpot();

  return (
    <ContactFormContextProvider>
      <Wrapper>
        <SEO />
        <Menu
          {...{
            ...defaultHeaderProps,

            primaryButtonText: undefined,
            primaryButtonUrl: undefined,
            primaryButtonElement: <JoinTheBetaButton />,
          }}
          simpleMenu
          logo={
            <TextLogoContainer>
              <XSpecsLogoVertical />
              <TextLogo>
                <div>
                  <TextLogoLine>The Spec-Driven </TextLogoLine>
                  <TextLogoLine>Development Platform</TextLogoLine>
                </div>
              </TextLogo>
            </TextLogoContainer>
          }
        />

        {props.children}

        <Footer {...defaultFooterProps} hideFooterLinks customRightsMessage={`X Specs, Inc. © ${new Date().getFullYear()}`} />
      </Wrapper>
    </ContactFormContextProvider>
  );
};

const TextLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const TextLogo = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const TextLogoLine = styled.span`
  @media ${device.mobile} {
    display: block;
  }
`;

const Wrapper = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;

  @font-face {
    font-family: "HK Grotesk";
    src: url(${HKGrotesk});
    src: url(${HKGrotesk}) format("opentype");
    font-display: block;
  }
  @font-face {
    font-family: "HK Grotesk";
    font-weight: bold;
    src: url(${HKGroteskBold});
    src: url(${HKGroteskBold}) format("opentype");
    font-display: block;
  }

  * {
    font-family: "HK Grotesk", sans-serif;
  }

  margin-bottom: 20px;
  @media ${device.desktop} {
    margin-bottom: 0px;
  }
`;
