import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const TwoColumnsWrapper = styled.div `
  display: flex;
  flex-direction: column-reverse;

  > * {
    width: 100%;
  }

  @media ${device.mobile} {
    padding: 0 ${spacing.mobile.padding.default}px;
  }

  @media ${device.tabletVertical} {
    flex-direction: ${(props) => props.imagePosition === "right" ? "row-reverse" : "row"};

    > * {
      width: 50%;
      height: ${(props) => props.height || "500px"};

      ${(props) => props.imagePosition === "right"
    ? `
                &:first-child {
                    margin-left: ${props.margin || "20px"};
                  }
            
                  &:nth-child(2) {
                    margin-right: ${props.margin || "20px"};
                  }
            `
    : `
                &:first-child {
                    margin-right: ${props.margin || "20px"};
                  }
            
                  &:nth-child(2) {
                    margin-left: ${props.margin || "20px"};
                  }
            `}
    }
  }
`;

export { TwoColumnsWrapper };
