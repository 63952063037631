import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { a as TextMedium } from '../../../colors-c52fac99.js';
import { TextBody } from '../typography.js';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary.js';
import { EnvelopeIcon } from '../Icons/EnvelopeIcon.js';
import { Loader } from '../Loader/Loader.js';

const xolvioNewsletterUrl = "https://simasdasdian.us9.list-manage.com/subscribe/post?u=658edfe7fca9a502c46dd331e&amp;id=7f9763afd9";
let id = 0;
class SubscriptionInput extends React__default.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            loading: false,
            email: "",
        };
        this.componentDidMount = () => {
            if (this.props.autofocus) {
                setTimeout(() => this.field.focus(), 1);
            }
        };
        this.formSubmit = async (e) => {
            const formRef = this.formRef;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            const analytics = window && window.analytics;
            if (analytics && analytics.track && typeof analytics.track === "function") {
                analytics.track("Subscribed", Object.assign({ value: 1, email: this.state.email }, this.props.analyticsProps));
            }
            else {
                console.log("_____ analytics missing");
            }
            e.preventDefault();
            if (formRef.current) {
                try {
                    const formData = new FormData(formRef.current);
                    this.setState({
                        loading: true,
                    });
                    await fetch(this.props.contactActionUrl || xolvioNewsletterUrl, {
                        method: "POST",
                        body: formData,
                        mode: "no-cors",
                    });
                    // With no-cors the we can't really tell what happened with the request
                    this.setState({
                        submitted: true,
                        loading: false,
                    });
                }
                catch (error) {
                    console.log("_____ error submitting the form");
                }
            }
        };
        this.render = () => {
            if (this.state.loading) {
                return (React__default.createElement("div", null,
                    React__default.createElement(Loader, null)));
            }
            return (React__default.createElement("div", null, this.state.submitted ? (React__default.createElement(TextBody, { fontSize: "16" }, this.props.successMessage || "You're in!")) : (React__default.createElement(Form, { className: "contact-us", onSubmit: this.formSubmit, style: this.state.submitted ? { display: "none" } : {}, ref: this.formRef },
                React__default.createElement(EnvelopeIcon, null),
                React__default.createElement("input", Object.assign({ required: true, "data-testid": "newsletter-subscription", className: "form-control", type: "email", name: "EMAIL", placeholder: "Email", "aria-label": "Email", onChange: (evt) => this.setState({ email: evt.target.value }) }, this.props.inputProps)),
                React__default.createElement(ButtonPrimary, { type: "submit" }, this.props.buttonText || "Submit")))));
        };
        this.iframeid = `contact-us-iframe-${id++}`;
        this.formRef = React__default.createRef();
    }
}
const Form = styled.form `
  position: relative;

  input {
    padding: 0 0 0 60px;
    width: calc(100% - 63px);
    font-size: 14px;
    height: 46px;
    border-radius: 2px;
    border: solid 1px #d8dcdb;
    color: ${TextMedium};
    margin-top: 10px;
    margin-bottom: 16px;

    &::placeholder {
      color: #6b726f;
    }
  }

  svg {
    margin-top: 22px;
    margin-left: 12px;
    position: absolute;
    padding-right: 12px;
    border-right: 1px solid #d8dcdb;
  }

  button {
    cursor: pointer;
    display: block;
    margin: auto;
    width: 100%;
    font-size: 14px;
    height: 48px;
  }

  @media ${device.tablet} {
    input {
      margin-top: 5px;
      padding-right: 100px;
      width: calc(100% - 163px);

      &:focus {
        outline: none;
      }
    }

    button {
      position: absolute;
      width: auto;
      right: 0;
      top: 5px;
    }

    svg {
      margin-top: 17px;
    }
  }
`;

export default SubscriptionInput;
export { Form };
