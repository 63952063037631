import { withPrefix } from "gatsby-link";
import scrollToElement from "scroll-to-element";
import { navigate } from "@reach/router";

export const goToHomepageAnchor = elementId => {
  const location = window.location || {};
  const isHomepage = location.pathname === withPrefix("/");
  if (isHomepage) {
    scrollToElement(`#${elementId}`, { duration: 1000 });
  } else {
    navigate(`/#${elementId}`);
  }
};

export const appFormUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSfUUgyOuwuD6FA_w5WF2sEg_YhWYPTjTgCmnF1LOdOUyt-R6A/viewform";
// export const appUrl = 'https://app.xspecs.ai'

export const defaultHeaderProps = {
  items: [
    {
      name: "How it works",
      onClick: () => goToHomepageAnchor("how-it-works"),
      url: "/#how-it-works"
    },
    {
      name: "Use cases",
      onClick: () => goToHomepageAnchor("use-cases"),
      url: "/#use-cases"
    },
  ],
  primaryButtonText: "Join the Beta",
  primaryButtonUrl: appFormUrl,
  socialIcons: {
    twitter: "https://twitter.com/xolvio",
    linkedin: "https://www.linkedin.com/company/xolvio/",
    medium: "https://www.xolv.io/articles"
  }
};

export const defaultFooterProps = {
  contactActionUrl:
    "https://docs.google.com/forms/u/5/d/e/1FAIpQLScLxWXkOSoyGPIxNgTBXkDg2mp2zLI3QgMe94WgwQ4-nZ1bjw/formResponse",
  linkGroups: [
    {
      name: "LEARN MORE",
      links: [
        {
          name: "About Us",
          url: "https://xolv.io/about-us",
          target: "_blank"
        },
        {
          name: "Articles",
          url: "https://www.xolv.io/articles",
          target: "_blank"
        },
        {
          name: "Contact",
          url: "https://xolv.io/contact-us",
          target: "_blank"
        }
      ]
    },
    {
      name: "PRODUCTS",
      links: [
        {
          name: "XSpecs",
          url: "https://www.xspecs.ai/"
        },
        {
          name: "Chimp",
          url: "https://github.com/xolvio/chimp",
          target: "_blank"
        },
        {
          name: "Quality Faster",
          url: "https://www.qualityfaster.com/",
          target: "_blank"
        }
      ]
    },
  ],
  legaleseLinks: [
    {
      name: "Privacy policy",
      url: "/privacy-policy"
    }
  ],
  socialLinks: {
    twitter: "https://twitter.com/xolvio",
    linkedin: "https://www.linkedin.com/company/xolvio/"
  }
};
