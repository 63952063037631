import React, { createContext, useState, useContext } from 'react'
import {JoinTheBetaForm} from './JoinTheBetaForm'
import {Modal} from './Modal'

const contactFormContext = createContext()

export const useContactForm = () => useContext(contactFormContext)

export const ContactFormContextProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openContactForm = () => setModalIsOpen(true)
  const closeContactForm = () => setModalIsOpen(false)

  return (
    <contactFormContext.Provider value={{ openContactForm, closeContactForm }}>
      {children}
      {
        modalIsOpen && (
          <Modal onCloseClick={closeContactForm}>
            <JoinTheBetaForm />
          </Modal>
        )
      }
    </contactFormContext.Provider>
  )
}
