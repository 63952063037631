import { useEffect, useState } from 'react'

export const useHubSpot = () => {
const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined" && !loaded) {
      if (process.env.NODE_ENV === 'production' && !window.Cypress) {
        const script = document.createElement("script")
        script.src = "https://js.hs-scripts.com/24401327.js"
        script.id = 'hs-script-loader'
        script.async = true
        script.defer = true
        script.onload = () => {
          setLoaded(true)
          console.log('HS script loaded')
        }
        document.body.appendChild(script)
      } else {
        console.warn('HS is not loaded in development/test mode')
      }
    }
  }, [])
  return loaded
}
