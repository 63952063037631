import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

// TODO add story?
const Spacing = styled.div `
  height: ${spacing.mobile.betweenSections}px;
  width: 100%;

  @media ${device.tablet} {
    height: ${spacing.desktop.betweenSections}px;
  }
`;

export { Spacing };
