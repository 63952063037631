import React__default, { useState, useEffect } from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import '../ButtonPrimary/ButtonPrimary.js';
import '../Icons/TwitterIcon.js';
import '../Icons/LinkedInIcon.js';
import '../Icons/YoutubeIcon.js';
import '../SocialIcons/SocialIcons.js';
import './MenuItem.js';
import './MenuButtons.js';
import './MenuItemsWrapper.js';
import { HeaderDesktop } from './HeaderDesktop.js';
import './MenuToggleButton.js';
import { HeaderMobile } from './HeaderMobile.js';
import './SimpleMenuToggleButton.js';
import { HeaderMobileSimple } from './HeaderMobileSimple.js';
import '../Icons/XolvioLogo.js';
import { defaultHeaderProps } from './defaultHeaderProps.js';

// @ts-nocheck
function debounce(func, wait, immediate = false) {
    let timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate)
                func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
}

// heavily inspired by
const Menu = (extraProps) => {
    const props = Object.assign(Object.assign({}, defaultHeaderProps), extraProps);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;
        const direction = prevScrollPos - currentScrollPos < 0 ? "down" : "up";
        setVisible(direction === "up" || currentScrollPos < 110);
        setPrevScrollPos(currentScrollPos);
    }, 100, true);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible, handleScroll]);
    const navbarStyles = {
        position: "fixed",
        transition: visible ? "top 0.2s" : "top 0.3s",
        top: visible ? "0" : "-110px",
    };
    return (React__default.createElement(React__default.Fragment, null,
        React__default.createElement(OuterWrapper, { id: props.id, style: navbarStyles },
            React__default.createElement(MenuWrapper, null,
                props.simpleMenu ? (React__default.createElement(HeaderMobileSimple, Object.assign({}, props))) : (React__default.createElement(HeaderMobile, Object.assign({}, props))),
                React__default.createElement(HeaderDesktop, Object.assign({}, props)))),
        React__default.createElement("div", { style: { height: 110 } })));
};
const OuterWrapper = styled.div `
  z-index: 123;
  width: 100%;
  background: white;
  @media ${device.desktop} {
    min-height: 110px;
  }
`;
const MenuWrapper = styled(CenteredContentWrapper) `
  margin-bottom: 80px;
  max-width: 100vw;

  @media ${device.desktopUpToContentWidth} {
    max-width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  * {
    font-family: "HK Grotesk", Helvetica, sans-serif;
    line-height: 1.56;
  }

  > *,
  > * > * {
    background: white;
  }
  z-index: 99999;
  position: relative;
  width: 100%;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-2%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media ${device.desktop} {
    padding: 16px 0;
    display: flex;
    justify-content: right;
  }
`;

export { Menu };
