import React__default from 'react';
import styled from 'styled-components';

const IntegratingIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("g", { id: "Layer_1" },
        React__default.createElement("circle", { className: "cls-1", cx: "65", cy: "67", r: "3" }),
        React__default.createElement("circle", { className: "cls-2", cx: "55", cy: "63", r: "1" }),
        React__default.createElement("circle", { className: "cls-2", cx: "50", cy: "63", r: "1" }),
        React__default.createElement("circle", { className: "cls-2", cx: "45", cy: "63", r: "1" }),
        React__default.createElement("rect", { className: "cls-1", x: "42", y: "37", width: "17", height: "4" }),
        React__default.createElement("rect", { className: "cls-1", x: "42", y: "45", width: "10", height: "4" }),
        React__default.createElement("rect", { className: "cls-1", x: "42", y: "53", width: "15", height: "4" }),
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls-2", d: "M66,53.76V30h-24v2h22v21.76l-3.01,3.21v10.02h0c0,1.07,.42,2.07,1.17,2.83,.75,.76,1.76,1.17,2.83,1.17s2.07-.42,2.83-1.17c.76-.75,1.17-1.76,1.17-2.83h0v-10.02l-3-3.21Zm-1,1.86l2,2.15v5.8c-1.23-.69-2.78-.69-4.01,0v-5.8l2.01-2.15Zm1.41,12.79c-.76,.76-2.07,.76-2.83,0-.38-.38-.59-.88-.59-1.41s.21-1.04,.59-1.41,.88-.59,1.41-.59,1.04,.21,1.41,.59,.59,.88,.59,1.41-.21,1.04-.59,1.41Z" }),
            React__default.createElement("path", { className: "cls-2", d: "M38.5,60.5v-2c0-1.93-1.57-3.5-3.5-3.5v2c.83,0,1.5,.67,1.5,1.5v2c0,.98,.41,1.86,1.06,2.5-.65,.64-1.06,1.52-1.06,2.5v2c0,.83-.67,1.5-1.5,1.5v2c1.93,0,3.5-1.57,3.5-3.5v-2c0-.83,.67-1.5,1.5-1.5v-2c-.83,0-1.5-.67-1.5-1.5Z" }),
            React__default.createElement("path", { className: "cls-2", d: "M38,46v-2c-.83,0-1.5-.67-1.5-1.5v-2c0-.98-.41-1.86-1.06-2.5,.65-.64,1.06-1.52,1.06-2.5v-2c0-.83,.67-1.5,1.5-1.5v-2c-1.93,0-3.5,1.57-3.5,3.5v2c0,.83-.67,1.5-1.5,1.5v2c.83,0,1.5,.67,1.5,1.5v2c0,1.93,1.57,3.5,3.5,3.5Z" }),
            React__default.createElement("rect", { className: "cls-2", x: "42", y: "36", width: "17", height: "2" }),
            React__default.createElement("rect", { className: "cls-2", x: "42", y: "44", width: "10", height: "2" }),
            React__default.createElement("rect", { className: "cls-2", x: "42", y: "52", width: "15", height: "2" }))),
    React__default.createElement("g", { id: "_Layer_" },
        React__default.createElement("path", { className: "cls-1", d: "M17.7,50H6.3C6.3,26,25.75,6.45,49.71,6.29l4.29,5.71c-.1,0-4.29,5.7-4.29,5.7-17.67,.16-32.01,14.59-32.01,32.3Z" }),
        React__default.createElement("path", { className: "cls-1", d: "M82.3,50h11.4c0,24-19.45,43.55-43.41,43.71l-4.29-5.71c.1,0,4.29-5.7,4.29-5.7,17.67-.16,32.01-14.59,32.01-32.3Z" }),
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls-2", d: "M13,50C13,29.6,29.6,13,50,13c.51,0,1.02,.04,1.53,.06l-3.24,3.24,1.41,1.41,5.71-5.71-5.71-5.71-1.41,1.41,3.33,3.33c-.54-.02-1.08-.04-1.62-.04C28.5,11,11,28.5,11,50c0,16.17,10.17,30.84,25.31,36.52l.7-1.87c-14.37-5.39-24.02-19.31-24.02-34.65Z" }),
            React__default.createElement("path", { className: "cls-2", d: "M89,50c0-16.17-10.17-30.84-25.31-36.52l-.7,1.87c14.37,5.39,24.02,19.31,24.02,34.65,0,20.4-16.6,37-37,37-.51,0-1.02-.04-1.53-.06l3.24-3.24-1.41-1.41-5.71,5.71,5.71,5.71,1.41-1.41-3.33-3.33c.54,.02,1.08,.04,1.62,.04,21.5,0,39-17.5,39-39Z" })))));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
IntegratingIcon.defaultProps = {
    "data-testid": "icon-integrating",
};

export { IntegratingIcon };
