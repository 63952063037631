import React__default from 'react';
import styled from 'styled-components';

const ReadinessAssessmentIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("polygon", { className: "cls-1", points: "56 90 78 90 78 20 60 20 60 23 56 23.06 56 90" }),
    React__default.createElement("polygon", { className: "cls-2", points: "62 39.41 58.29 35.71 59.71 34.29 62 36.59 67.79 30.79 69.21 32.21 62 39.41" }),
    React__default.createElement("polygon", { className: "cls-2", points: "62 53.41 58.29 49.71 59.71 48.29 62 50.59 67.79 44.79 69.21 46.21 62 53.41" }),
    React__default.createElement("polygon", { className: "cls-2", points: "62 67.41 58.29 63.71 59.71 62.29 62 64.59 67.79 58.79 69.21 60.21 62 67.41" }),
    React__default.createElement("polygon", { className: "cls-2", points: "62 81.91 58.29 78.21 59.71 76.79 62 79.09 67.79 73.29 69.21 74.71 62 81.91" }),
    React__default.createElement("path", { className: "cls-2", d: "M61,19v-3h-6.1c-.47-2.28-2.48-4-4.9-4s-4.43,1.72-4.9,4h-6.1v3h-13v60h26v-2H28V21h11v3h22v-3h11v57h2V19h-13Zm-2,3h-18v-4h6v-1c0-1.65,1.35-3,3-3s3,1.35,3,3v1h6v4Z" }),
    React__default.createElement("rect", { className: "cls-2", x: "35", y: "35", width: "12", height: "2" }),
    React__default.createElement("rect", { className: "cls-2", x: "35", y: "49", width: "17", height: "2" }),
    React__default.createElement("rect", { className: "cls-2", x: "35", y: "63", width: "12", height: "2" })));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
ReadinessAssessmentIcon.defaultProps = {
    "data-testid": "icon-readiness-assessment",
};

export { ReadinessAssessmentIcon };
