import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import '../ButtonPrimary/ButtonPrimary.js';
import '../../../InlineButton-54d8331c.js';
import '../Icons/Confetti9Rows.js';
import { IconTout } from '../IconTout/IconTout.js';
import '../ImageTout/ToutImageConfettiWrapper.js';
import { ImageTout } from '../ImageTout/ImageTout.js';

const ToutGroupOld = (props) => (React__default.createElement(CenteredContentWrapper, { style: props.style, className: "tout-group", id: props.id },
    React__default.createElement(IconToutsWrapper, null,
        React__default.createElement(IconTout, Object.assign({}, props.iconTout1)),
        React__default.createElement(IconTout, Object.assign({}, props.iconTout2))),
    React__default.createElement(ImageTout, Object.assign({}, props.imageTout))));
const IconToutsWrapper = styled.div `
  display: flex;
  flex-direction: column;

  padding: 20px 0;

  @media ${device.mobile} {
    > *:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    flex-direction: row;
    > * {
      width: 50%;

      // that's ugly but hard to achieve another way
      // it adds margin to second tout icon, so its text content is
      // aligned with the content of the image tout below
      :not(:nth-child(1)) {
        > * {
          :nth-child(1) {
            margin-left: ${spacing.mobile.padding.default}px;
          }
        }
      }
    }
  }
`;

export { ToutGroupOld };
