import React__default from 'react';

const JiraLogo = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", height: props.size || "80", viewBox: "0 0 214 80" }, props),
    React__default.createElement("title", null, "Jira logo"),
    React__default.createElement("defs", null,
        React__default.createElement("linearGradient", { id: "a", x1: "67.804%", x2: "40.86%", y1: "40.347%", y2: "81.723%" },
            React__default.createElement("stop", { offset: "18%", stopColor: "#0052CC" }),
            React__default.createElement("stop", { offset: "100%", stopColor: "#2684FF" })),
        React__default.createElement("linearGradient", { id: "b", x1: "32.76%", x2: "59.353%", y1: "59.158%", y2: "18.079%" },
            React__default.createElement("stop", { offset: "18%", stopColor: "#0052CC" }),
            React__default.createElement("stop", { offset: "100%", stopColor: "#2684FF" }))),
    React__default.createElement("g", { fill: "none", fillRule: "nonzero" },
        React__default.createElement("path", { fill: "#253858", d: "M109.9 11h7.9v40.2c0 10.7-4.7 18-15.6 18-3.2 0-6.4-.5-9.5-1.5V60c2.6 1 5.4 1.5 8.2 1.5 6.7 0 9-4.1 9-9.8V11zM132.2 7.9c2.7-.2 5.1 1.9 5.3 4.6v.7c0 2.9-2.4 5.3-5.3 5.3s-5.3-2.4-5.3-5.3c-.2-2.7 1.9-5.1 4.6-5.3h.7zm-3.9 16.9h7.6v44h-7.6v-44zM154.8 68.8h-7.4v-44h7.4v7.8c2.6-5.2 6.9-8.9 15.6-8.4v7.4c-9.7-1-15.6 1.9-15.6 11.3v25.9zM205.8 60.9c-2.8 5.8-8.1 8.8-14.9 8.8-11.7 0-17.6-9.9-17.6-22.9 0-12.4 6.2-22.9 18.5-22.9 6.4 0 11.4 2.9 14 8.6v-7.8h7.6v44h-7.6v-7.8zm-12.9 1.7c6.8 0 12.9-4.3 12.9-14.1V45c0-9.8-5.5-14.1-12-14.1-8.5 0-12.9 5.6-12.9 15.8 0 10.6 4.2 15.9 12 15.9z" }),
        React__default.createElement("path", { fill: "#2684FF", d: "M73 37.8L40.4 5.2 37.2 2 12.7 26.5 1.5 37.8C.3 39 .3 40.9 1.5 42l22.4 22.4 13.3 13.3 24.5-24.5.4-.4L73 42c1.2-1.2 1.2-3.1 0-4.2zM37.2 51.1L26 39.9l11.2-11.2 11.2 11.2-11.2 11.2z" }),
        React__default.createElement("path", { fill: "url(#a)", d: "M37.2 26.7c-7.3-7.3-7.4-19.2-.1-26.6L12.6 24.6 26 37.9l11.2-11.2z", transform: "translate(0 2)" }),
        React__default.createElement("path", { fill: "url(#b)", d: "M48.5 37.8L37.2 49.1c7.4 7.4 7.4 19.3 0 26.7l24.6-24.6-13.3-13.4z", transform: "translate(0 2)" }))));
JiraLogo.defaultProps = {
    "data-testid": "jira-logo",
};

export { JiraLogo };
