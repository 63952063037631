import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../../spacing-ad86e0c5.js';
import { d as device } from '../../../../device-db5dbdea.js';
import { d as AlertRed, a as TextMedium } from '../../../../colors-c52fac99.js';
import { Subheading, SectionHeaderSmall, TextBody } from '../../typography.js';
import { CenteredContentWrapper } from '../../layout/CenteredContentWrapper.js';
import { ButtonPrimary } from '../../ButtonPrimary/ButtonPrimary.js';
import { EnvelopeIcon } from '../../Icons/EnvelopeIcon.js';
import { UserIcon } from '../../Icons/UserIcon.js';
import { Loader } from '../../Loader/Loader.js';
import 'axios';
import { u as useContactFormLogic, F as FormStatus } from '../../../../useContactFormLogic-be2eca8a.js';
import '../../Icons/CheckIcon.js';
import '../../Icons/CheckIconThin.js';
import { ListChecked } from '../../ListChecked/ListChecked.js';

//https://stackoverflow.com/a/13419367
function parseQuery(queryString) {
    const query = {};
    const pairs = (queryString[0] === "?"
        ? queryString.substr(1)
        : queryString).split("&");
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
}

const DEFAULT_SUBJECT = "general";
const ContactUsNew = (props) => {
    const logic = useContactFormLogic("contact-us-submit", "Xolv.io - contact form", {
        subject: (parseQuery(props.searchQuery).subject || DEFAULT_SUBJECT)
    });
    switch (logic.status) {
        case FormStatus.ERROR:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(SectionHeaderSmall, { style: { color: AlertRed, maxWidth: "100%" } }, "Oops, something went wrong. Please try again.")));
        case FormStatus.SUCCESS:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(SectionHeaderSmall, { id: "contact-form-submitted", style: {
                        maxWidth: "100%",
                        textAlign: "center",
                        fontWeight: 100,
                        fontSize: "22px",
                    } }, "Thank you for your message. We\u2019ll be in touch shortly.")));
        case FormStatus.LOADING:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(LoaderWrapper, null,
                    React__default.createElement(Loader, null))));
        default:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(ColumnWrapper, null,
                    React__default.createElement(Subheading, null, "WHAT YOU GET"),
                    React__default.createElement(SectionHeaderSmall, null, "Pre-engagement consultation workshop"),
                    React__default.createElement(TextBody, null, "At Xolvio, we believe that requirements gathering is the most difficult aspect of software delivery. This is why we lead projects with accelerated, collaborative requirements facilitation techniques that bring stakeholder knowledge into the visual realm to inform software design."),
                    React__default.createElement(TextBody, null, "By the end of the 90 min. session you are going to get:"),
                    React__default.createElement(ListChecked, null, [
                        'A set of key insights and specific, actionable recommendations on how to solve your problem efficiently and with the future in mind.',
                        'Improved stakeholder alignment, visibility, and clarity on the problem at hand.',
                        'Discover new, collaborative ways of working between verticals.'
                    ])),
                React__default.createElement(ColumnWrapper, null,
                    React__default.createElement(FormWrapper, null,
                        React__default.createElement("form", { onSubmit: logic.onSubmit },
                            React__default.createElement(Subheading, null, "GET IN TOUCH"),
                            React__default.createElement(SectionHeaderSmall, null, "Request consultation"),
                            React__default.createElement(TextBody, null, "Please send the below form and we will get back to you to schedule your pre-engagement consultation workshop."),
                            React__default.createElement(InputWithIconWrapper, null,
                                React__default.createElement(UserIcon, null),
                                React__default.createElement("input", { name: "name", onChange: logic.onChange, placeholder: "Your name", type: "text", "data-testid": "name-input", required: true })),
                            React__default.createElement(InputWithIconWrapper, null,
                                React__default.createElement(EnvelopeIcon, null),
                                React__default.createElement("input", { name: "email", onChange: logic.onChange, placeholder: "Your email", type: "email", "data-testid": "email-input", required: true })),
                            React__default.createElement(Textarea, { name: "message", onChange: logic.onChange, "data-testid": "message", placeholder: "Enter your message" }),
                            React__default.createElement(ContactUsSendButton, { type: "submit" }, "Send"))))));
    }
};
ContactUsNew.defaultProps = {
    searchQuery: "",
};
const ContactUsSendButton = styled(ButtonPrimary) `
  width: 100%;
  @media ${device.mobile} {
    width: 265px;
  }
`;
const Wrapper = styled(CenteredContentWrapper) `
  padding: ${spacing.mobile.padding.default}px;
  display: flex;
  flex-direction: row;
  gap: 60px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;
const FormWrapper = styled.div `
  margin-bottom: 50px;
`;
const InputWithIconWrapper = styled.div `
  position: relative;

  input {
    padding: 0 0 0 60px;
    width: calc(100% - 63px);
    font-size: 14px;
    height: 46px;
    border-radius: 2px;
    border: solid 1px #d8dcdb;
    color: ${TextMedium};
    margin-top: 10px;
    margin-bottom: 16px;

    &::placeholder {
      color: #6b726f;
    }
  }

  svg {
    margin-top: 22px;
    margin-left: 12px;
    position: absolute;
    padding-right: 12px;
    border-right: 1px solid #d8dcdb;
  }
`;
const Textarea = styled.textarea `
  resize: vertical;
  border-radius: 2px;
  border: solid 1px #d8dcdb;
  max-width: 100%;
  width: calc(100% - 36px);
  min-height: 120px;
  padding: 24px 16px;
  margin-top: 10px;
  margin-bottom: 16px;

  font-family: "HK Grotesk";
  font-size: 14px;
  letter-spacing: -0.2px;
  color: ${TextMedium};
`;
const RadioWrapper = styled.div `
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  input {
    margin-right: 10px;
  }
`;
const LoaderWrapper = styled.div `
  min-height: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;

  > * {
    display: block;
    margin: auto;
  }
`;
const ColumnWrapper = styled.div `
  margin-top: 20px;
  flex: 1;
`;

export { ContactUsNew, InputWithIconWrapper, RadioWrapper, Textarea };
