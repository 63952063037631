import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { Confetti3x5 } from '../Icons/Confetti3x5.js';
import { Confetti5x9 } from '../Icons/Confetti5x9.js';

const ImageConfettiWrapper = ({ elementWithBackgroundImage, style, flipConfetti, topConfettiSide, }) => (React__default.createElement(Wrapper, { style: style },
    React__default.createElement(ImageWrapper, null,
        React__default.createElement(Mobile, null,
            React__default.createElement(Confetti3x5, { className: "non-tablet-up", "data-testid": "confetti-image-top", style: {
                    position: "absolute",
                    right: topConfettiSide === "right" ? 0 : undefined,
                    left: topConfettiSide === "left" ? 0 : undefined,
                    zIndex: -1,
                    transform: flipConfetti ? "scaleX(-1)" : "",
                } })),
        React__default.createElement(Desktop, null,
            React__default.createElement(Confetti5x9, { className: "tablet-up", "data-testid": "confetti-image-top", style: {
                    position: "absolute",
                    right: topConfettiSide === "right" ? -40 : undefined,
                    left: topConfettiSide === "left" ? -40 : undefined,
                    top: -20,
                    zIndex: -1,
                    transform: flipConfetti ? "scaleX(-1)" : "",
                } })),
        React__default.createElement(BackgroundImageWrapper, { "data-testid": "confetti-image" }, elementWithBackgroundImage),
        React__default.createElement(Mobile, { style: { position: "absolute" } },
            React__default.createElement(Confetti3x5, { className: "non-tablet-up", style: {
                    transform: !flipConfetti ? "scaleX(-1)" : "",
                    position: "absolute",
                    top: -40,
                    right: topConfettiSide === "left" ? 0 : undefined,
                    left: topConfettiSide === "right" ? 0 : undefined,
                }, "data-testid": "confetti-image-bottom" })),
        React__default.createElement(Desktop, { style: { position: "absolute" } },
            React__default.createElement(Confetti5x9, { className: "tablet-up", style: {
                    transform: !flipConfetti ? "scaleX(-1)" : "",
                    position: "absolute",
                    top: -60,
                    right: topConfettiSide === "left" ? -40 : undefined,
                    left: topConfettiSide === "right" ? -40 : undefined,
                }, "data-testid": "confetti-image-bottom" })))));
ImageConfettiWrapper.defaultProps = {
    topConfettiSide: "right",
};
const Wrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;
const BackgroundImageWrapper = styled.div `
  > * {
    width: 286px;
    height: 266px;

    max-width: 100%;
    background-size: cover;

    margin: 17px auto 0;

    @media ${device.tabletVertical} {
      width: 100%;
      height: 700px;
    }
  }
`;
const ImageWrapper = styled.div `
  display: block;
  width: 100%;

  max-width: 100%;
`;
const Mobile = styled.div `
  width: 100%;
  @media ${device.desktop} {
    visibility: hidden;
    display: none;
  }
`;
const Desktop = styled.div `
  width: 100%;
  position: absolute;
  @media ${device.mobile} {
    display: none;
  }
`;

export { ImageConfettiWrapper };
