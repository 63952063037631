import styled from 'styled-components';
import Button from './Button.js';

const EmbedActionButton = styled(Button) `
  display: inline-flex;
  align-items: center;
  margin: 5px 5px 5px 15px;
  font-family: HelveticaNeue;
  font-size: 0.8rem;
  color: black;
  height: 1.5rem;
  line-height: 1.89em;
  padding: 0 10px 0 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export { EmbedActionButton };
