import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../../spacing-ad86e0c5.js';
import { d as device } from '../../../../device-db5dbdea.js';
import { d as AlertRed, a as TextMedium } from '../../../../colors-c52fac99.js';
import { Subheading, SectionHeaderSmall, TextBody } from '../../typography.js';
import { CenteredContentWrapper } from '../../layout/CenteredContentWrapper.js';
import { ButtonPrimary } from '../../ButtonPrimary/ButtonPrimary.js';
import { EnvelopeIcon } from '../../Icons/EnvelopeIcon.js';
import { UserIcon } from '../../Icons/UserIcon.js';
import { Loader } from '../../Loader/Loader.js';
import { Spacing } from '../../layout/Spacing.js';
import 'axios';
import { u as useContactFormLogic, F as FormStatus } from '../../../../useContactFormLogic-be2eca8a.js';
import '../../../../_commonjsHelpers-97e6d7b1.js';
import '../../../../_react_commonjs-external-04d0b979.js';
import { CalendlyScheduleButton } from '../CalendlyScheduleButton.js';

//https://stackoverflow.com/a/13419367
function parseQuery(queryString) {
    const query = {};
    const pairs = (queryString[0] === "?"
        ? queryString.substr(1)
        : queryString).split("&");
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
}

const DEFAULT_SUBJECT = "general";
const ContactUs = (props) => {
    const logic = useContactFormLogic("contact-us-submit", "Xolv.io - contact form", {
        subject: (parseQuery(props.searchQuery).subject || DEFAULT_SUBJECT)
    });
    switch (logic.status) {
        case FormStatus.ERROR:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(SectionHeaderSmall, { style: { color: AlertRed, maxWidth: "100%" } }, "Oops, something went wrong. Please try again.")));
        case FormStatus.SUCCESS:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(SectionHeaderSmall, { id: "contact-form-submitted", style: {
                        maxWidth: "100%",
                        textAlign: "center",
                        fontWeight: 100,
                        fontSize: "22px",
                    } }, "Thank you for your message. We\u2019ll be in touch shortly.")));
        case FormStatus.LOADING:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement(LoaderWrapper, null,
                    React__default.createElement(Loader, null))));
        default:
            return (React__default.createElement(Wrapper, null,
                React__default.createElement("form", { onSubmit: logic.onSubmit },
                    React__default.createElement(Subheading, null, "OPTION 1"),
                    React__default.createElement(SectionHeaderSmall, null, "Send a message"),
                    React__default.createElement(InputWithIconWrapper, null,
                        React__default.createElement(UserIcon, null),
                        React__default.createElement("input", { name: "name", onChange: logic.onChange, placeholder: "Your name", type: "text", "data-testid": "name-input", required: true })),
                    React__default.createElement(InputWithIconWrapper, null,
                        React__default.createElement(EnvelopeIcon, null),
                        React__default.createElement("input", { name: "email", onChange: logic.onChange, placeholder: "Your email", type: "email", "data-testid": "email-input", required: true })),
                    React__default.createElement(Textarea, { name: "message", onChange: logic.onChange, "data-testid": "message", placeholder: "Enter your message" }),
                    React__default.createElement(ButtonPrimary, { type: "submit", style: { width: "100%" } }, "Send")),
                React__default.createElement(SpacingSmaller, null),
                React__default.createElement(StyledScheduleCallWrapper, null,
                    React__default.createElement(Subheading, null, "OPTION 2"),
                    React__default.createElement(SectionHeaderSmall, null, "Schedule a 30m call"),
                    React__default.createElement(TextBody, null, "Click the button below to choose the best date & time for you via Calendly."),
                    React__default.createElement(CalendlyScheduleButton, null)),
                React__default.createElement(SpacingSmaller, null)));
    }
};
ContactUs.defaultProps = {
    searchQuery: "",
    method: "post",
    termsAcceptanceRequired: true,
};
const StyledScheduleCallWrapper = styled.div `
  max-width: 100%;
  * {
    max-width: unset;
  }
`;
const Wrapper = styled(CenteredContentWrapper) `
  padding: ${spacing.mobile.padding.default}px;
`;
const InputWithIconWrapper = styled.div `
  position: relative;

  input {
    padding: 0 0 0 60px;
    width: calc(100% - 63px);
    font-size: 14px;
    height: 46px;
    border-radius: 2px;
    border: solid 1px #d8dcdb;
    color: ${TextMedium};
    margin-top: 10px;
    margin-bottom: 16px;

    &::placeholder {
      color: #6b726f;
    }
  }

  svg {
    margin-top: 22px;
    margin-left: 12px;
    position: absolute;
    padding-right: 12px;
    border-right: 1px solid #d8dcdb;
  }
`;
const Textarea = styled.textarea `
  resize: vertical;
  border-radius: 2px;
  border: solid 1px #d8dcdb;
  max-width: 100%;
  width: calc(100% - 36px);
  min-height: 150px;
  padding: 24px 16px;
  margin-top: 10px;
  margin-bottom: 16px;

  font-family: "HK Grotesk";
  font-size: 14px;
  letter-spacing: -0.2px;
  color: ${TextMedium};
`;
const RadioWrapper = styled.div `
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: baseline;
  input {
    margin-right: 10px;
  }
`;
const LoaderWrapper = styled.div `
  min-height: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;

  > * {
    display: block;
    margin: auto;
  }
`;
const SpacingSmaller = styled(Spacing) `
  @media ${device.mobile} {
    height: 24px !important;
    max-height: 24px !important;
  }
  @media ${device.tabletVertical} {
    height: 40px !important;
    max-height: 40px !important;
  }
`;

export { ContactUs, InputWithIconWrapper, RadioWrapper, Textarea };
