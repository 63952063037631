import React from "react";
import styled, { css } from "styled-components";
import { spacing, JiraLogo } from "@xolvio/xolvio-ui";
import { TextBodySmall } from "../components/common";
import { TileContentScreenshotTemplate } from "../components/Benefits/TileContentScreenshotTemplate";

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: ${spacing.mobile.padding.default}px;
  }
`;

export const benefitsProductOwnersContent = {
  feedbackFor: [],
  moreBenefits: (
    <>
      <TextBodySmall>
        XSpecs allow you to communicate the business value and intended outcomes
        clearly and precisely, which reduces guesswork and leads to a smooth
        delivery cycle. This means you can build the product right the first
        time around through a shared understanding of what needs to be done.
      </TextBodySmall>
      <TextBodySmall>
        Unlike acceptance criteria on tickets which are ephemeral, specs are
        long lived and act as a means for you to know what the system
        capabilities are today and in the future.
      </TextBodySmall>
    </>
  ),

  toolsUsedHeading: "INTEGRATIONS",
  toolsUsed: (
    <Icons>
      <JiraLogo size={24} />
    </Icons>
  ),
};

const summaryCustomCss = css`
  margin-left: 0;
`;

export const benefits = {
  subHeading: "HOW IT WORKS",
  header: "Spec-Driven Development",
  body: "XSpecs accelerates backend development from weeks to hours through a streamlined process backed by AI.",
  sections: [
    {
      heading: "Create a doc",
      summary:
        "Define your software requirements in a doc with rich text, images, & embedded content.",
      icon: <></>,
      iconDesktop: <></>,
      children: <TileContentScreenshotTemplate num={1} />,
      desktopChildren: <TileContentScreenshotTemplate num={1} />,
      summaryCustomCss,
    },
    {
      heading: "Generate & edit specs",
      summary:
        "Pick a portion of your doc for the AI assistant to turn it into an editable spec suggestion.",
      icon: <></>,
      iconDesktop: <></>,
      children: <TileContentScreenshotTemplate num={2} />,
      desktopChildren: <TileContentScreenshotTemplate num={2} />,
      summaryCustomCss,
    },
    {
      heading: "Manage change",
      summary:
        "As your requirements evolve, update your specs accordingly using changesets to stay on track.",
      icon: <></>,
      iconDesktop: <></>,
      children: <TileContentScreenshotTemplate num={3} />,
      desktopChildren: <TileContentScreenshotTemplate num={3} />,
      summaryCustomCss,
    },
    {
      heading: "Collaborate with your team",
      summary:
        "Edit specs together for a shared understanding. Connect your specs to JIRA to plan & execute delivery.",
      icon: <></>,
      iconDesktop: <></>,
      children: <TileContentScreenshotTemplate num={4} />,
      desktopChildren: <TileContentScreenshotTemplate num={4} />,
      summaryCustomCss,
    },
  ],
};
