import React__default from 'react';
import styled from 'styled-components';
import { A as AcceptanceGreen } from '../../../colors-c52fac99.js';

const Loader = () => (React__default.createElement(LoaderWrapper, null,
    React__default.createElement("div", null),
    React__default.createElement("div", null),
    React__default.createElement("div", null),
    React__default.createElement("div", null)));
const LoaderWrapper = styled.div `
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${AcceptanceGreen};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.8s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.8s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.8s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.8s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export { Loader };
