import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader } from '../typography.js';
import '../Emphasis/Emphasis.js';
import { EmphasizedText } from '../EmphasizedText/EmphasizedText.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import { HeroBackgroundWrapperNew } from './HeroBackgroundNew.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';

const HeroNew = (props) => {
    let titleContent;
    if (props.title) {
        if (Array.isArray(props.title)) {
            titleContent = (React__default.createElement(React__default.Fragment, null, props.title.map(line => (React__default.createElement("div", { key: line },
                React__default.createElement(EmphasizedText, { text: line, highlight: props.highlightedTitleFragments }))))));
        }
        else {
            titleContent = React__default.createElement(EmphasizedText, { text: props.title, highlight: props.highlightedTitleFragments });
        }
    }
    return (React__default.createElement(Wrapper, { style: props.style, id: props.id },
        React__default.createElement(HeroBackgroundWrapperNew, { height: props.height, "data-testid": props["data-testid"], inline: props.inline }, props.elementWithBackgroundImage),
        !props.inline && (React__default.createElement(ImageOverlay, { className: "hero-image-overlay" },
            React__default.createElement(TitleContainer, null, titleContent),
            React__default.createElement(ContentBodyContainer, null, props.contentBlock),
            React__default.createElement(ActionButtons, null, props.actionButtons),
            React__default.createElement(ButtonPrimaryLink, { href: "/contact-us", style: { marginTop: spacing.mobile.padding.default }, as: "a" }, "Request consultation"))),
        React__default.createElement(SectionContent, { inline: props.inline },
            props.inline && (React__default.createElement(React__default.Fragment, null,
                React__default.createElement(Subheading, null, props.subheading),
                React__default.createElement(SectionHeader, null, props.heading))),
            React__default.createElement(ChildrenWrapper, null, props.children))));
};
HeroNew.defaultProps = {
    contentBlock: () => null,
    actionButtons: () => null,
    "data-testid": "hero",
};
const TitleContainer = styled.div `
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -0.43px;
  margin: 40px 0;
`;
const ContentBodyContainer = styled.div `
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 30px;
  margin: 16px 0 20px;
`;
const Wrapper = styled.div `
  position: relative;
  @media ${device.mobile} {
    margin-top: -50px;
  }
`;
const ActionButtons = styled.div `
  margin-top: 25px;
`;
const ImageOverlay = styled(CenteredContentWrapper) `
  position: absolute;
  top: 75px;

  @media ${device.wide} {
    left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
  }

  padding-top: 10px;
  max-width: 500px;

  @media ${device.tabletOnly} {
    > * {
      max-width: 100%;
    }
  }

  @media ${device.mobile} {
    position: relative;
    top: -35px;

    background: #ffffff;
    left: 0;
    margin: 0 ${spacing.mobile.padding.default}px;
    max-width: 100%;

    h1 {
      margin-top: 0;
      padding-right: 24px;
    }

    > * {
      text-align: center;
    }
  }
`;
const SectionContent = styled.div `
  position: relative;
  top: -75px;
  padding-top: 48px;
  background: #ffffff;
  left: 0;
  margin: 0 ${spacing.mobile.padding.default}px -75px;
  max-width: 100%;
  min-height: 50px;

  @media ${device.mobile} {
    top: -50px;
    padding-top: 0;

    h1 {
      padding-top: 30px;
      padding-right: 24px;
      margin-top: 0;
    }
  }

  > * {
    text-align: left;
  }

  h1 {
    padding-right: 24px;
  }

  > * {
    text-align: left;
  }

  @media ${device.desktop} {
    top: -146px;
    margin-right: auto;
    margin-left: 0;

    max-width: ${spacing.desktop.maxContentWidth}px;
    padding-left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
  }

  ${(props) => props.inline
    ? `
    @media ${device.mobile} {
    padding-top: 24px;
    margin-top: 24px;
    }
      
    @media ${device.desktop} {
        padding-left: 24px;
    }
    
    @media ${device.wide} {
        padding-left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
    }
    `
    : ""}
`;
const ChildrenWrapper = styled.div `
  position: relative;
  @media ${device.mobile} {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  @media ${device.tablet} {
    margin-bottom: 90px;
  }

  @media ${device.desktop} {
    margin-top: initial;
    margin-bottom: initial;
  }
`;

export { HeroNew };
