import React from "react";
import styled from "styled-components";
import { CenteredContentWrapper, device, colors } from "@xolvio/xolvio-ui";

export const Modal = ({ children, onCloseClick, isOpen }) => (
  <Wrapper onClick={onCloseClick}>
    <InnerWrapper onClick={e => e.stopPropagation()}>
      {children}
      <Close onClick={onCloseClick} />
    </InnerWrapper>
  </Wrapper>
);

const Close = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0 7px;
  cursor: pointer;
  :after {
    font-size: 2em;
    color: ${colors.AcceptanceGreen};
    content: "\\00d7";
  }

  @media ${device.tablet} {
    padding: 10px;

    :after {
      font-size: 1em;
    }
  }
`;

const InnerWrapper = styled(CenteredContentWrapper)`
  background-color: white;
  overflow-y: scroll;
  padding: 0;
  margin: auto !important;

  height: fit-content;
  position: relative;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    height: 0;
    width: 0;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: 50px;
  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.66);
  z-index: 10000;

  * {
    max-width: calc(100vw - 48px);
  }
`;
