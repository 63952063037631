import { createElement } from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const Clients = (props) => (createElement(Wrapper, null,
    createElement(InnerWrapper, { "data-testid": "client-logos" }, props.logoBackgroundImages.map((logo, index) => (createElement(LogoWrapper, { key: index }, logo))))));
const Wrapper = styled.div `
  overflow: scroll;
  max-width: 100%;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    height: 0;
    width: 0;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;
const InnerWrapper = styled.div `
  width: fit-content;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;

  > * {
    filter: grayscale(80%);
    opacity: 0.5;
  }

  @media ${device.desktop} {
    padding: 0;
  }
`;
const LogoWrapper = styled.div `
  > * {
    height: 100px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
`;

export { Clients, LogoWrapper };
