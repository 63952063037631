import React__default from 'react';

const XSpecsLogoVertical = (props) => (React__default.createElement("svg", Object.assign({ width: "143", height: "48", viewBox: "0 0 143 48", version: "1.1", xmlns: "http://www.w3.org/2000/svg" }, props),
    React__default.createElement("title", null, "XSpecs logo with name"),
    React__default.createElement("defs", null,
        React__default.createElement("path", { id: "prefix__a", d: "M0.606 0.242L9.665 0.242 9.665 13.536 0.606 13.536z" })),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { fill: "#292828", d: "M76.818 29.632L74.136 25.366 71.477 29.632 67.917 29.632 72.356 23.209 68.48 17.168 71.838 17.168 74.136 20.919 76.412 17.168 79.771 17.168 75.894 23.209 80.379 29.632z" }),
        React__default.createElement("path", { fill: "#56BF98", d: "M85.666 30.036c-1.398 0-2.863-.54-3.742-1.639-.653-.831-.856-1.661-.946-2.694h3.065c.203.897.608 1.594 1.645 1.594.721 0 1.352-.494 1.352-1.259 0-.224-.045-.449-.18-.65-.406-.562-2.006-.99-2.637-1.213-1.668-.585-2.794-1.73-2.794-3.571 0-2.267 2.006-3.862 4.192-3.862 2.186 0 3.988 1.638 4.078 3.816h-2.93c-.134-.65-.518-1.077-1.194-1.077-.63 0-1.148.45-1.148 1.077 0 .654.518.833 1.036 1.034.36.135.72.247 1.081.36 1.938.605 3.494 1.415 3.494 3.728 0 2.469-1.893 4.356-4.372 4.356M98.2 19.481c-2.164 0-3.696 1.82-3.696 3.908 0 1.549.743 2.896 2.186 3.547.45.225.946.36 1.464.36 2.12 0 3.651-1.887 3.651-3.907 0-1.999-1.487-3.908-3.605-3.908m.338 10.555c-1.668 0-2.681-.472-3.854-1.594v5.345h-2.998V17.168h2.75v1.46h.045c1.037-1.303 2.411-1.886 4.057-1.886 3.673 0 6.264 3.054 6.264 6.601 0 3.526-2.59 6.693-6.264 6.693M112.362 19.481c-1.825 0-3.11 1.077-3.538 2.853h6.919c-.294-1.797-1.555-2.853-3.381-2.853m6.265 5.256h-9.714c.069.336.227.629.407.897.676 1.034 1.802 1.662 3.042 1.662 1.218 0 2.276-.493 2.885-1.594h3.155c-.812 2.538-3.38 4.334-6.04 4.334-3.74 0-6.603-2.897-6.603-6.603 0-3.727 2.794-6.69 6.581-6.69 3.65 0 6.467 2.874 6.467 6.488 0 .495-.067 1.012-.18 1.506M126.709 30.036c-3.696 0-6.648-3.032-6.648-6.692 0-3.638 2.974-6.602 6.625-6.602 2.93 0 5.364 1.796 6.288 4.56h-3.38c-.655-1.17-1.51-1.82-2.908-1.82-2.23 0-3.628 1.773-3.628 3.908 0 2.155 1.533 3.907 3.764 3.907 1.217 0 2.14-.719 2.772-1.73h3.38c-.992 2.739-3.313 4.469-6.265 4.469" }),
        React__default.createElement("g", { transform: "translate(133.216 16.5)" },
            React__default.createElement("mask", { id: "prefix__b", fill: "#fff" },
                React__default.createElement("use", { xlinkHref: "#prefix__a" })),
            React__default.createElement("path", { fill: "#56BF98", d: "M5.293 13.536c-1.397 0-2.862-.54-3.741-1.639-.653-.832-.856-1.661-.946-2.694H3.67c.202.897.608 1.594 1.644 1.594.721 0 1.353-.494 1.353-1.258 0-.225-.046-.45-.18-.652-.406-.56-2.006-.989-2.637-1.212-1.668-.585-2.794-1.73-2.794-3.571 0-2.267 2.005-3.862 4.19-3.862 2.188 0 3.99 1.638 4.08 3.816H6.398c-.135-.65-.52-1.077-1.195-1.077-.63 0-1.15.45-1.15 1.077 0 .654.52.833 1.036 1.034.362.135.721.247 1.083.36 1.938.605 3.493 1.415 3.493 3.728 0 2.469-1.892 4.356-4.372 4.356", mask: "url(#prefix__b)" })),
        React__default.createElement("path", { fill: "#56BF98", d: "M0 48L48.168 48 48.168 0 0 0z" }),
        React__default.createElement("path", { fill: "#FFF", d: "M26.46 24.923l-8.872 8.84c-.881.879-2.419.879-3.302 0-.44-.439-.684-1.024-.684-1.645 0-.622.243-1.206.684-1.645L18 26.77l-2.128-2.12-3.714 3.7c-1.01 1.006-1.567 2.344-1.567 3.767s.557 2.76 1.567 3.767c1.009 1.006 2.352 1.56 3.78 1.56 1.427 0 2.77-.554 3.78-1.56l8.871-8.84-2.128-2.122zM21.707 23.078l8.873-8.841c.882-.88 2.42-.88 3.302 0 .441.44.684 1.023.684 1.645 0 .621-.243 1.206-.684 1.646l-3.705 3.692 2.128 2.122 3.706-3.693c1.01-1.006 1.566-2.344 1.566-3.767s-.556-2.76-1.566-3.766c-1.01-1.007-2.352-1.561-3.78-1.561s-2.77.554-3.78 1.56l-8.873 8.842 2.129 2.121z" }),
        React__default.createElement("path", { fill: "#FFF", d: "M21.3 28.762l2.13-2.122-9.145-9.113c-.436-.442-.685-1.027-.685-1.642 0-.623.249-1.208.685-1.65.88-.878 2.424-.878 3.304 0l3.44 3.435 2.13-2.123-3.44-3.435c-1.008-1.005-2.356-1.56-3.778-1.56-1.43 0-2.77.555-3.786 1.56-1.008 1.005-1.565 2.348-1.565 3.773 0 1.417.557 2.76 1.565 3.765l9.145 9.112zM30.588 22.949l-3.723-3.71-2.128 2.12 3.723 3.71 5.422 5.404c.441.44.684 1.023.684 1.645 0 .621-.243 1.205-.684 1.645-.882.879-2.42.879-3.303 0l-3.444-3.432-2.129 2.121 3.445 3.433c1.01 1.005 2.352 1.56 3.78 1.56 1.427 0 2.77-.555 3.78-1.56 1.01-1.006 1.566-2.344 1.566-3.767s-.557-2.761-1.566-3.766l-5.423-5.403z" }))));
XSpecsLogoVertical.defaultProps = {
    "data-testid": "xspecs-logo-vertical",
};

export { XSpecsLogoVertical };
