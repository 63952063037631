import React__default from 'react';
import styled from 'styled-components';

const MenuToggleButton = (props) => {
    const styles = {
        lineTop: {
            transform: props.open ? "rotate(45deg)" : "none",
            transformOrigin: "top left",
            marginBottom: "8px",
        },
        lineMiddle: {
            opacity: props.open ? 0 : 1,
            transform: props.open ? "translateX(-16px)" : "none",
        },
        lineBottom: {
            transform: props.open ? "translateX(-1px) rotate(-45deg)" : "none",
            transformOrigin: "top left",
            marginTop: "9px",
        },
    };
    return (React__default.createElement(MenuToggleButtonWrapper, { onClick: props.onClick },
        React__default.createElement(Line, { style: styles.lineTop }),
        React__default.createElement(Line, { style: styles.lineMiddle }),
        React__default.createElement(Line, { style: styles.lineBottom })));
};
const MenuToggleButtonWrapper = styled.div `
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
`;
const Line = styled.div `
  height: 2px;
  width: 30px;
  background: #56bf98;
  transition: all 0.2s ease;
`;

export { MenuToggleButton };
